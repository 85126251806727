import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, query, orderBy, getDocs, limit, startAfter, where, updateDoc, doc, increment, getDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBAzeDd9hKkxIle1INAsD10saOrtJJZXcQ",
    authDomain: "standwithus-be281.firebaseapp.com",
    databaseURL: "https://standwithus-be281-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "standwithus-be281",
    storageBucket: "standwithus-be281.appspot.com",
    messagingSenderId: "21822051662",
    appId: "1:21822051662:web:2efc4b9b5a883a68d97a1f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const collections = {
    requests: "requests",
    items: "items",
    clicks: "clicks",
}
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
export async function addLinkToReport(url) {
    try {
        const requestsRef = collection(db, collections.requests);
        const q = query(requestsRef, where("url", "==", url));
        const response = await getDocs(q);
        if (response?.docs?.length) {
            return;
        }
        await addDoc(collection(db, collections.requests), {
            url
        });
    } catch (error) {
        console.error(error)
    }

}

let lastVisible;
export async function getList(sort, page) {
    let documents = [];
    let hasMore = false;
    try {
        const requestsRef = collection(db, collections.items);
        if (page === 0) {
            const q = query(requestsRef, orderBy(sort, 'desc'), limit(12));
            const response = await getDocs(q);
            lastVisible = response.docs[response.docs.length - 1];
            documents = response.docs
        }
        else {
            const q = query(requestsRef, orderBy(sort, 'desc'), startAfter(lastVisible), limit(3));
            const response = await getDocs(q);

            lastVisible = response.docs[response.docs.length - 1];
            documents = response.docs
        }
        const q = query(requestsRef, orderBy(sort, 'desc'), startAfter(lastVisible), limit(3));
        const response = await getDocs(q);
        if (response?.docs?.length) hasMore = true;
    } catch (error) {
        console.error(error)

    }
    return { documents, hasMore };
}

export async function addReport(id, count) {
    try {
        const docToUpdate = doc(db, collections.items, id);
        const docClicks = doc(db, collections.clicks, 'clicks');
        await updateDoc(docToUpdate, {
            reportsCount: increment(1)
        });
        await updateDoc(docClicks, {
            count: increment(1)
        });
    } catch (error) {
        console.error(error)
    }

}

export async function getReports() {
    try {
        const docClicks = doc(db, collections.clicks, 'clicks');
        const document = await getDoc(docClicks);
        return document.get('count');
    } catch (error) {
        console.error(error)
    }
}