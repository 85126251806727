
import { useState } from 'react';
import TopBar from './components/TopBar/TopBar';
import Sort from './components/Sort/Sort';
import AddLink from './components/AddLink/AddLink';
import ItemList from './components/ItemList/ItemList';
import './App.css';

function App() {
  const [sort, setSort] = useState('createdAt');
  return (
    <div className="app">
      <TopBar/>
      <AddLink/>
      <Sort callback={setSort}/>
      <ItemList sort={sort}/>
    </div>
  );
}

export default App;
