import styles from './Sort.module.css';
import classNames from 'classnames';
import { useState } from 'react';

function Sort({ callback }) {
    const [selected, setSelected] = useState('createdAt');
    const handleClick = (selectedValue) => {
        callback(selectedValue);
        setSelected(selectedValue)
    }
    return (


        <div>
            <div className={styles.explain}><div className={styles.explain2}>בכדי לדווח יש לבחור בלינק מהרשימה ולדווח בהתאם לפלטפורמה.</div></div>
            <div className={styles.sort}>

                <div className={styles.reportsTitle}> רשימת דיווחים </div>

                <button className={classNames(styles.button, { [`${styles.selected}`]: selected === 'reportsCount' })} type="button" onClick={() => handleClick('reportsCount')}>
                    הכי מדווחים
                </button>
                <button className={classNames(styles.button, { [`${styles.selected}`]: selected === 'createdAt' })} type="button" onClick={() => handleClick('createdAt')}>
                    אחרונים
                </button>
            </div>
        </div>

    );
}

export default Sort;
