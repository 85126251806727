import { useCallback, useState, useEffect } from 'react';
import Item from '../Item/Item';
import styles from './ItemList.module.css'
import { getList } from '../../firebase/firebase';

function ItemList({ sort }) {
    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);

    const loadFunction = useCallback(() => {
        setLoading(true);
        getList(sort, page).then(({ documents, hasMore }) => {
            setList(documents ? [...list, ...documents] : list);
            setPage(page + 1);
            setHasMore(hasMore);
            setLoading(false);
        });
    }, [page, sort, list])

    useEffect(() => {
        setLoading(true);
        getList(sort, 0).then(({ documents, hasMore }) => {
            setList(documents || []);
            setPage(1);
            setHasMore(hasMore);
            setLoading(false);
        });
    }, [sort]);

    return (
        <div className={styles.itemList}>
            {list.map(item => <Item item={item} key={item.id} />)}
            <div className={styles.margin}>
            {!isLoading && hasMore && <button className={styles.load} type='button' onClick={loadFunction} disabled={isLoading}><u className={styles.loadText}>טען עוד...</u></button>}
            </div>
        </div>
    )
}

export default ItemList;
