import styles from './AddLink.module.css';
import classNames from 'classnames';
import { useState } from 'react';
import { addLinkToReport } from '../../firebase/firebase'

function AddLink() {
    const [link, setLink] = useState('');
    const [disabled, setDisabled] = useState(false);

    const handleClick = async () => {
        if (link && typeof link === 'string') {
            setDisabled(true);
            try {
                new URL(link);
                await addLinkToReport(link);
            } catch (error) {
            }
            setTimeout(() => {
                setDisabled(false)
            }, 5 * 1000);
            setLink('');
        }
    }

    return (
        <div className={styles.addLink}>
            <input className={styles.addLinkInput} onChange={(event) => setLink(event.target.value)} value={link} placeholder='לדיווח, הוסיפו קישור כאן'/>
            <button className={classNames(styles.button, { [`${styles.disabled}`]: disabled === true })} type="button" onClick={handleClick}>דווח</button>
        </div>
    );
}

export default AddLink;
