import styles from './TopBar.module.css';
import { useState, useEffect } from 'react';
import { getReports } from '../../firebase/firebase';

function TopBar() {
    const [reports, setReports] = useState(0);
    useEffect(() => {
        getReports().then(reports => setReports(reports))
    })
    return (
        <div className={styles.topBar}>
            <div className={styles.titleBar}>
                <div className={styles.title}>
                    עוצרים את השקרים
                </div>
                <div className={styles.title2}>מדווחים על עמודים שקריים!</div>
            </div>

            <div className={styles.icons}>
                <div className={styles.reportsWrapper}>
                    <img className={styles.iconBig} src="./icons/flash.svg" alt="reports" />
                    <div className={styles.reports}>{`סה"כ דיווחים`}</div>
                    <div className={styles.reportsNum}>{`${reports}`}</div>
                </div>
            </div>
        </div>
    );
}

export default TopBar;
