import styles from './Item.module.css';
import classNames from 'classnames';
import { addReport } from '../../firebase/firebase';
import { useEffect, useState } from 'react';

const iconsMap = {
    facebook: "./icons/facebook.svg",
    instagram: "./icons/instagram.svg",
}

function Item({ item }) {
    const { url, platform, isSensetive, reportsCount, isDown } = item.data();
    const [isChecked, setChecked]  = useState(false);

    useEffect(() => {
        const id = item.id;
        const checked = localStorage.getItem(id)
        setChecked(Boolean(checked));
    }, [item.id])

    const id = item.id;
    const handleClick = async () => {
        if (localStorage.getItem(id)) return;
        localStorage.setItem(id, 'true');
        setChecked(true);
        await addReport(id);
    }
    return (
        <a className={styles.link} href={url} target="_blank" rel="noreferrer" onClick={handleClick}>
            <div className={classNames(styles.item, { [styles.reportDown]: isDown })}>
                <div className={styles.itemProperties}>
                    <img className={styles.icon} alt="icon" src={iconsMap[platform] || "./icons/global.svg"} />
                    <div className={classNames(styles.online, styles.border)}>{isDown ? "ירד מהרשת" : "עדיין ברשת"}</div>
                    <div className={classNames(styles.reports, styles.border)}>
                        <img alt="count" src={"./icons/flash-black.svg"} />
                        {`${reportsCount} דיווחים`}
                    </div>
                    {isSensetive && <>
                        <div className={styles.border}>  <img alt="sensetive" src="./icons/eye-off.svg" /> </div>
                    </>}
                </div>
                <img alt={isChecked ? 'checked' : "not checked" } src={ isChecked ? './icons/checked.svg' : './icons/check.svg'}/>
            </div>
        </a>

    );
}

export default Item;
